import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './DetailedAnalytics.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';

const DetailedAnalytics = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/reporting/detailed-analytics.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              body
              litem {
                item
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.analyticsContainer} d-flex justify-content-center`}>
      <div className={`${styles.textContainer} col-md-6 px-0`}>
        <Title className={styles.title}>{content.title}</Title>
        <Text className={styles.analyticText}>
          {content.body}
          <ul>
            {content.litem.map((listing, index) => (
              <li key={index}>
                <span>{listing.item}</span>
              </li>
            ))}
          </ul>
        </Text>
      </div>
      <div className="col-md-6 px-0">
        <Image name="report_phone" className={styles.analyticsImage} />
      </div>
    </div>
  );
};

export default DetailedAnalytics;
