import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './SubscriberActivity.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';

const SubscriberActivity = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/reporting/subscriber-activity.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              body
              litem {
                item
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.activityContainer}>
      <div className="col-md-12 px-0">
        <Title className={styles.activityTitle}>{content.title}</Title>
      </div>
      <div className={`${styles.activityInner} d-flex justify-content-center`}>
        <div className="col-md-6">
          <Image name="report_person" className={styles.personImage} />
        </div>
        <Text className={`${styles.activityText} col-md-6 px-0`}>
          {content.body}
          <ul className={`${styles.manageCampaignSlide__list}`}>
            {content.litem.map((listing, index) => (
              <li key={index}>
                <span>{listing.item}</span>
              </li>
            ))}
          </ul>
          {content.footer_text}
        </Text>
      </div>
    </div>
  );
};

export default SubscriberActivity;
