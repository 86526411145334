import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './WeeklyReport.module.css';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';

const WeeklyReport = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/reporting/weekly-report.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              body
              litem {
                item
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.weeklyContainer}>
      <Title className={styles.title}>{content.title}</Title>
      <div className={`${styles.weeklyInner} d-flex justify-content-center`}>
        <div className="col-md-6 px-0">
          <Image name="report_weekly" className={styles.imageBig} />
        </div>
        <div className={`${styles.list} col-md-6 px-0`}>
          <ol>
            {content.litem.map((listing, index) => (
              <li key={index}>
                <span>{listing.item}</span>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default WeeklyReport;
