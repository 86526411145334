import React from 'react';

import Layout from '../../components/blocks/Layout';
import SEO from '../../components/blocks/SEO';
import SubscriberActivity from '../../components/slides/features/reporting/SubscriberActivity';
import CampaignNumbers from '../../components/slides/features/reporting/CampaignNumbers/CampaignNumbers';
import DetailedAnalytics from '../../components/slides/features/reporting/DetailedAnalytics/DetailedAnalytics';
import WeeklyReport from '../../components/slides/features/reporting/WeeklyReport/WeeklyReport';

const Features = () => (
  <Layout>
    <SEO
      title="Reporting | Deadline Funnel"
      description="Create a personal journey for each of your subscribers, and present unique offers to each person through your emails and landing pages."
    />
    <CampaignNumbers />
    <SubscriberActivity />
    <DetailedAnalytics />
    <WeeklyReport />
  </Layout>
);

export default Features;