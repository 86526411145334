import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './CampaignNumbers.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';
import Breadcrumbs from '../../../../blocks/Breadcrumbs';

const CampaignNumbers = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/reporting/campaign-numbers.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
              litem {
                item
              }
              footer_text
            }
          }
        }
      }
    }
  `);

  const content = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.campaignNumbersContainer} d-flex justify-content-center`}>
      <div className={`${styles.textContainer} col-md-6 px-0`}>
        <Breadcrumbs crumbs={['<a href="/features">Features</a>', 'Reporting']} />
        <Title className={styles.numberTitle}>{content.title}</Title>
        <Text className={styles.textWrapper}>
          {content.subtitle}
          <ul>
            {content.litem.map((listing, index) => (
              <li key={index}>
                <span>{listing.item}</span>
              </li>
            ))}
          </ul>
          {content.footer_text}
        </Text>
      </div>
      <div className="col-md-6 px-0">
        <Image name="campaign_numbers" className={styles.imageContainer} />
        <Image name="campaign_numbers_mobile" className={styles.imageContainerMobile} />
      </div>
    </div>
  );
};

export default CampaignNumbers;
